<template>
  <div>
    <h1>爱答教育隐私权政策</h1>
    <p>更新日期：2021年9月1日</p>

    <p>生效日期：2021年9月1日</p>

    <p>
      广西爱答教育科技有限公司（以下简称“我们”）系aida101.com的运营者，我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
      我们提供的产品/服务（包括未设独立隐私政策的的产品/服务），均适用本隐私政策。这些产品/服务包括爱答教育网、客户端以及相关微信开放平台账号或小程序等。此外，针对某些特定的产品/服务，我们还将制定单独的隐私政策，向您说明这些产品/服务的特殊政策。如相关特定的隐私政策与本隐私政策有不一致之处，适用该特定隐私政策。
      本政策将帮助您了解以下内容：
    </p>
    <p>
      一、个人信息定义及范围
    </p>
    <p>
      二、我们如何收集和使用您的个人信息
    </p>
    <p>
      三、我们如何使用 Cookie 和同类技术
    </p>
    <p>
      四、我们如何共享、转让、公开披露您的个人信息
    </p>
    <p>
      五、我们如何保护您的个人信息
    </p>
    <p>
      六、您如何管理您的个人信息
    </p>
    <p>
      七、我们如何处理儿童的个人信息
    </p>
    <p>
      八、本隐私权政策如何更新
    </p>
    <p>
      九、如何联系我们
    </p>

    <p>
      【特别提示】请您在使用我们提供的各项产品及服务前，仔细阅读并充分理解本《隐私权政策》（重点内容我们已将字体加粗请您特别关注）并作出相应选择。一旦您使用或继续使用我们的产品及服务时，即意味着您同意我们按照本隐私政策处理您的相关信息。如对本隐私政策有任何疑问，您可以通过本隐私政策“如何联系我们”中提供的方式与我们联系。
    </p>
    <p>
      一、个人信息定义及范围
    </p>
    <p>
      1、个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中可能涉及到的个人信息包括但不限于：个人基本信息（包括姓名、电话号码、性别、住址、生日等）；个人身份信息（包括身份证信息等）；网络身份标识信息（包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（包括银行账号、交易和消费记录及虚拟财产信息等）；个人上网记录（包括网站浏览记录、点击记录等）；个人设备信息（包括唯一设备识别码等描述个人常用设备基本情况的信息）；个人位置信息等。
    </p>
    <p>
      2、个人敏感信息：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策中可能涉及到的个人敏感信息包括：个人身份认证信息、个人财产信息及其他信息（位置信息、通讯录信息等）。
    </p>
    <p>
      二、我们如何收集和使用您的个人信息
    </p>
    <p>
      （一）在您使用我们的产品/服务时，您需要/可以选择授权我们收集和使用个人信息的包括：
    </p>
    <p>
      1、设备信息：我们会根据您在软件安装及使用中授权的具体权限，接受并记录您所使用的设备相关信息（如设备型号、操作系统版本、设备设置、唯一设备识别符、设备环境等软硬件特征信息）、设备所在位置相关信息（如IP地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站等传感器信息）及您所授予的设备权限使用所获信息。如您在安装及/或使用过程中拒绝授予我们相应权限，我们并不会记录相应信息；
    </p>
    <p>
      2、服务日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如搜索查询内容、课程的观看记录、课程的观看时长、IP地址、浏览器的类型、电信运营商、访问日期和时间以及您的访问记录等。
    </p>
    <p>
      3、请您理解，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。除非将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合期间，这类非个人信息将被视为个人信息，我们会将该类个人信息做匿名化、去标识化处理（已取得您授权或法律法规另有规定的情况除外）。
    </p>
    <p>
      4、当您与我们联系时，为验证您的身份，帮助您解决问题，我们可能会记录您与我们的对话并收集其他需要解决问题所需的必要信息。
    </p>
    <p>
      （二）您需要授权我们收集和使用个人信息的场景
    </p>
    <p>
      1.帮助您成为“爱答教育”的用户
    </p>
    <p>
      为方便您使用我们的产品/服务，您需要首先成为我们的注册用户，以便我们为您提供服务，您需要提供注册、登录爱答教育账号使用的电话号码，用户自行填写的姓名或昵称、用户自行上传的头像，并创建爱答教育的账号和密码。在您主动注销账号之后，我们将根据法律的要求删除您的个人信息，或使其匿名化处理。
    </p>
    <p>
      在您使用身份认证的功能或电子签章服务所需时，根据相关法律法规，您可能需要提供您的真实身份信息（真实姓名、身份证号码、电话号码）以完成实名验证。
      这些信息属于个人敏感信息，您可以拒绝提供，如果拒绝提供您将可能无法购买相关课程，但不影响其他功能与服务的正常使用。
    </p>
    <p>
      2.向您提供产品或服务
    </p>
    <p>
      2.1当您访问爱答教育时，或使用我们提供的服务时，我们可能会记录用户操作的相关信息（包括但不限于用户的计算机IP地址、设备标识符、硬件型号、操作系统版本及与爱答教育服务相关的日志信息），以便优化爱答教育为您提供更好的服务。您对此予以理解并同意。
    </p>
    <p>
      2.2当您选择参加我们的商业活动时，根据活动需要可能使用到您提供个人信息与第三方支付服务账号。这些信息可能包括个人敏感信息（个人电话号码、准考证号、支付宝账号、银行账号），是您收到转账或者礼品所必要的。如果您拒绝提供这些信息，我们将可能无法向您转账或发放礼品。
    </p>
    <p>
      2.3我们的客服和售后功能会使用你的账号信息为您提供咨询服务。
    </p>
    <p>
      当您与我们联系时，我们可能会保存您的通信或通话记录和内容或您留下的联系方式，以便帮助您解决问题，或记录相关问题的处理方案及结果。我们的客服会使用您的账号信息与您核验您的身份。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息。
    </p>
    <p>
      2.4业务功能
    </p>
    <p>
      2.4.1 注册、登录、认证
    </p>
    <p>
      a.当您注册、登录爱答教育及相关服务时，您可以通过手机号创建帐号，并且您可以完善相关的网络身份识别信息（头像、姓名/昵称、密码），收集这些信息是为了帮助您完成注册。您还可以根据自身需求选择填写性别、生日、地区及个人介绍来完善您的信息。如果您仅需要浏览、搜索服务，您不需要注册成为我们的账号及提供前述信息。
    </p>
    <p>
      b.在您使用电子签章服务中身份认证的功能时，根据相关法律法规，您可能需要提供您的真实身份信息（真实姓名、身份证号码、电话号码）以完成实名验证。
      这些信息属于个人敏感信息，您可以拒绝提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。
    </p>
    <p>
      2.4.2 购买、补款、退费
    </p>
    <p>
      a.您在爱答教育购买课程产品时，您需要提供联系人姓名、收货地址、联系方式，以便我们向您邮寄相应书籍及物资。
    </p>
    <p>
      b.您在爱答教育上支付时，您可以选择与爱答教育合作的第三方支付机构（如微信支付、支付宝等支付通道）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的订单信息及对账信息与这些支付机构共享以确认您的支付指令并完成支付。
    </p>
    <p>
      c.当您购买我们先上岸后补款的课程及/或退费课程时，您需要与我们签订电子合同，我们需要您提供您的真实姓名、手机号码、联系地址以便我们顺利签署合同。
    </p>
    <p>
      d.
      当您购买我们先上岸后补款的课程及/或退费课程时，为证明您符合补款/退费的情形，您需要给我们提供您的准考证号、报考岗位信息（包括但不限于地区、详细岗位等）、过关情况，以便我们判断您是否符合合同约定的补款/退费情形。若发生退费的，您还需要给我们提供您的姓名、银行卡号、开户行等信息，以便我们及时向您退费。
    </p>
    <p>
      e.爱答教育实物商品系第三方配送，我们为了顺利、安全、准确完成货物的交付及配送，第三方配送公司在发货机配送环节不可避免地会获知相关配送信息，我们会严格要求第三方配送公司对用户的个人信息保密，只以配送之目的获悉和使用，不得对外泄露或做其他用途。
    </p>
    <p>
      （三）为您提供安全保护保障
    </p>
    <p>
      您的网络身份标识信息帮助您维护软件正常使用及使用安全。
    </p>
    <p>
      我们将通过弹出窗口询问您是否可以获取相应权限，只有您选择同意后，我们才会为您开启相应权限并接受您的个人信息。
    </p>
    <p>
      个人信息匿名化处理：在收集到您的个人信息后，我们将通过技术手段及时对数据进行匿名化处理。我们会将匿名化后的数据与可用于回复识别个人的信息分开存储，并保证在后续的个人信息处理中不重新识别个人。在不泄露您个人信息的前提下，我们有权对匿名化处理后的用户数据库进行挖掘、分析和利用（包括商业性使用）。
    </p>
    <p>
      若我们对于您的个人信息用于除政策列明之外的目的、范围或与使用于数据收集不一致的传输方式时，我们将会通知您并取得您的同意。
    </p>
    <p>
      （四）征得授权同意的例外
    </p>
    <p>
      根据相关法律法规规定及国家标准，以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意：
    </p>
    <p>
      （1）与国家安全、国防安全直接相关的；
    </p>
    <p>
      （2）与公共安全、公共卫生、重大公共利益直接相关；
    </p>
    <p>
      （3）与犯罪侦查、起诉、审判和判决执行等直接相关的；
    </p>
    <p>
      （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
    </p>
    <p>
      （5）所收集的个人信息是您自行向社会公众公开的；
    </p>
    <p>
      （6）从合法公开披露的信息中收集个人信息的，如：合法的新闻报道、政府信息公开等渠道；
    </p>
    <p>
      （7）根据您的要求签订和履行合同所必须的；
    </p>
    <p>
      （8）用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现或处置产品或服务的故障；
    </p>
    <p>
      （9）为新闻单位或学术研究机构基于新闻报道、公共利益开展统计或学术研究所必要等，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理，且数据接收方无法复原并重新识别个人信息的；
    </p>
    <p>
      （10）法律法规规定的其他情形。
    </p>
    <p>
      三、我们如何使用 Cookie 和同类技术
    </p>
    <p>
      为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的设备终端/系统上存储名为
      Cookie的小数据文件。Cookie会帮助您在后续访问我们网站时调用您的信息，简化您填写个人信息(例如一键登录等)的流程;为您提供安全购物的偏好设置;帮助您优化对广告的选择与互动;保护您的数据安全等。我们不会将
      Cookie用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
      Cookie。您可以清除计算机或移动设备上保存的所有
      Cookie，您有权接受或拒绝Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookie;另外，您也可以清除软件内保存的所有Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。
    </p>
    <p>
      四、我们如何共享、转让、公开披露您的个人信息
    </p>
    <p>
      （一）共享
    </p>
    <p>
      我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：
    </p>
    <p>
      1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
    </p>
    <p>
      2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
    </p>
    <p>
      3、与授权合作伙伴共享：我们将审慎评估第三方使用共享信息的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
    </p>
    <p>
      （二）转让
    </p>
    <p>
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </p>
    <p>
      1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
    </p>
    <p>
      2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
    </p>
    <p>
      （三）公开披露
    </p>
    <p>
      （1）根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息并且无需征得您的同意:
    </p>
    <p>
      1、与国家安全、国防安全直接相关的；
    </p>
    <p>
      2、与公共安全、公共卫生、重大公共利益直接相关的；
    </p>
    <p>
      3、与犯罪侦查、起诉、审判和判决执行等直接相关的；
    </p>
    <p>
      4、出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；
    </p>
    <p>
      5、所收集的个人信息是您自行向社会公众公开的；
    </p>
    <p>
      6、从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；
    </p>
    <p>
      7、根据您的要求签订和履行合同所必需的；
    </p>
    <p>
      8、用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置产品或服务的故障；
    </p>
    <p>
      9、为合法的新闻报道所必需的；
    </p>
    <p>
      10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
    </p>
    <p>
      11、法律法规规定的其他情形。
    </p>
    <p>
      （2）对违规帐号、欺诈行为进行处罚公告时，我们会披露相关帐号的信息。
    </p>
    <p>
      （3）您公开发布的作品、动态、喜欢（点赞）将会在个人主页中展示。
    </p>
    <p>
      五、我们如何保护您的个人信息
    </p>
    <p>
      （一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
      我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </p>
    <p>
      （二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
    </p>
    <p>
      （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
    </p>
    <p>
      （四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
    </p>
    <p>
      （五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
    </p>
    <p>
      同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
    </p>
    <p>
      六、您如何管理您的个人信息
    </p>
    <p>
      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
    </p>
    <p>
      （一）访问您的个人信息
    </p>
    <p>
      您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
    </p>
    <p>
      账户信息——如果您希望访问或编辑您的账户中的个人资料信息、邮寄地址信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过 aida101.com 设置修改，或者通过文末提供的方式联系我们执行此类操作。
    </p>
    <p>
      订单信息——您可以在我们的网站、客户端等服务中查询到订单信息、物流信息、听课记录，您可以通过文末提供的方式联系删除此类信息，我们将在核实您的身份后提供，但法律另有规定的除外。
    </p>
    <p>
      其他信息——如您在访问过程中遇到操作问题或如需获取其他前述无法直接获知的个人信息内容，您可通过本文文末的方式联系我们，我们将在核实您的身份后提供，但法律另有规定的除外。
    </p>
    <p>
      您发表的评论或其他信息可能被其他访问者浏览和下载。为此，我们鼓励您在决定是否在其他参与者可浏览页面上发布可能显示您的身份的任何信息时慎重思考。
    </p>
    <p>
      （二）更正您的个人信息
    </p>
    <p>
      当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
    </p>
    <p>
      如果您无法通过上述链接更正这些个人信息，您可以联系客服。我们将在15天内回复您的更正请求。但出于安全性和身份识别的考虑，您可能无法修改注册时提交的某些初始注册信息。
    </p>
    <p>
      （三）删除您的个人信息
    </p>
    <p>
      在以下情形中，您可以向我们提出删除个人信息的请求：
    </p>
    <p>
      1、如果我们处理个人信息的行为违反法律法规；
    </p>
    <p>
      2、如果我们收集、使用您的个人信息，却未征得您的同意；
    </p>
    <p>
      3、如果我们处理个人信息的行为违反了与您的约定；
    </p>
    <p>
      4、如果您不再使用我们的产品或服务，或您注销了账号；
    </p>
    <p>
      5、如果我们不再为您提供产品或服务。
    </p>
    <p>
      若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
    </p>
    <p>
      当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    </p>
    <p>
      （四）保存您的个人信息
    </p>
    <p>
      我们会按照法律的规定，将境内收集到的您的个人信息存储于中国境内。将来如需跨境运输或存储的，我们会告知您信息出境的目的、接收方、安全措施及安全风险等信息，并征得您的同意。
    </p>
    <p>
      我们仅在为您提供 aida101.com 各项服务功能之目范围内保留您的个人信息，在您正常使用 aida101.com 期间，我们会正常保留您的个人信息。当您主动删除、主动注销账户或我们的产品或服务停止运营时，我们会在我们的产品中告知您，并在合理的期限范围内删除您的个人信息或进行匿名化处理。
    </p>
    <p>
      （五）注销您的账户
    </p>
    <p>
      您有权注销您的爱答教育账户，您可以联系客服核实并注销本人账户。
    </p>
    <p>
      （六）改变您授权同意的范围
    </p>
    <p>
      如您想更改相关功能的授权范围（例如相机、麦克风、通知、日历等），你可以通过您的硬件设备修改个人设置、或在我们的产品和服务中的相关功能设置界面进行操作处理。如您在此过程中遇到操作问题的，可以通过本政策文末提供的方式联系我们。
    </p>
    <p>
      当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </p>
    <p>
      （七）约束信息系统自动决策
    </p>
    <p>
      在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
    </p>
    <p>
      （八）响应您的上述请求
    </p>
    <p>
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    </p>
    <p>
      我们将在15日内做出答复。
    </p>
    <p>
      对于您合理的请求，我们原则上不收取费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
    </p>
    <p>
      在以下情形中，按照法律法规要求，我们将无法响应您的请求：
    </p>
    <p>
      1、与国家安全、国防安全有关的；
    </p>
    <p>
      2、与公共安全、公共卫生、重大公共利益有关的；
    </p>
    <p>
      3、与犯罪侦查、起诉和审判等有关的；
    </p>
    <p>
      4、有充分证据表明您存在主观恶意或滥用权利的；
    </p>
    <p>
      5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
    </p>
    <p>
      七、我们如何处理儿童的个人信息
    </p>
    <p>
      我们的产品、网站和服务主要面向成人。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
    </p>
    <p>
      八、本隐私权政策如何更新
    </p>
    <p>
      我们可能适时会对本隐私权政策进行调整或变更，本隐私权政策的任何更新将公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私权政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。
    </p>
    <p>
      九、如何联系我们
    </p>
    <p>
      您可以通过爱答教育网站/App上提供的在线联系方式/客服系统与我们联系。
    </p>
  </div>
</template>
<script>
export default {};
</script>

<style lang="less" scoped>
p {
  font-size: 1rem;
  margin: 5px 0px;
  color: #323233;
  letter-spacing: 0.44px;
  font-weight: 500;
}
</style>
